main li{

  margin: 0 0 1.5rem 0;
  padding: 0 0 0 1.5rem;
  position: relative;


  // arrows for list items
  &::before{
    position: absolute;
    height: 0;
    width: 0;
    top: 0.4rem;
    left: 0;
    border: 5px solid transparent;
    border-left: 5px solid @stdTextColor;
    content: "";
  }
}

main .dark li{
  //&::before{display: none;}
}



// INDEX
// Contact List
main .contact--list{ // == wrapping row

  ul{
    margin-top: 10rem;

    @media @smL{
      margin-top: 2rem;
    }

    li{
      display: flex;
      width: 100%;
      border-left: 3px solid @blue;
      padding-left: 1.5rem;
      background: @white;
      margin-bottom: 1rem;
      box-sizing: border-box;

      &::before{
        display: none;
      }

      i{
        width: 24px;
        margin-right: 0.5rem;
        padding-top: 12px;
      }

      a{
        padding: 0.5rem 1rem 0.5rem 0
      }

      @media @smL{
        padding: 0.5rem 0 0.5rem 1.5rem;
      }
    }
  }
}


