//@import url('https://fonts.googleapis.com/css?family=Montserrat:300,400,400i,500,500i,700');
//@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500');

@stdFontSize: 1rem;
@stdLineHeight: 1.5;

@stdTextColor:    #262a2d;
@black:           #000;
@darkgrey:        #0b0f12;
@mediumgrey:      #667;
@lightGrey:       #f1f1f1;
@white:           #fff;

@bodybg:          #f6f8f8;

@blue:            #013f6c;
@mediumblue:      #3a6a8d;
@lightblue:       #cad8e5;
@darkblue:        #262a2d;
@mediumblue:      #798188;

@warningColor:    #82052b;


@stdFont: "Quicksand", Helvetica, Calibri, sans-serif;
@linkFont: "Roboto", Helvetica, Calibri, sans-serif;
@fontawesome: "Fontawesome";

@h1size:    1.75rem;
@h2size:    1.25rem;



@maxwidth: 1280px;

@tabL:  ~"only screen and (max-width: 1279px)";
@tabP:  ~"only screen and (max-width: 853px)";
@tabP_only:  ~"only screen and (max-width: 853px) and (min-width: 481px)";
@smL:   ~"only screen and (max-width: 620px)";
@smP:   ~"only screen and (max-width: 480px)";
@smX:   ~"only screen and (max-width: 384px)";


.halfSecondTransition(){
  -webkit-transition: 0.5s ease-out;
  -moz-transition: 0.5s ease-out;
  -ms-transition: 0.5s ease-out;
  -o-transition: 0.5s ease-out;
  transition: 0.5s ease-out;
}

.oneSecondTransition(){
  -webkit-transition: 1s ease-out;
  -moz-transition: 1s ease-out;
  -ms-transition: 1s ease-out;
  -o-transition: 1s ease-out;
  transition: 1s ease-out;
}

.interactionTransition(){
  -webkit-transition: 0.33s ease;
  -moz-transition: 0.33s ease;
  -ms-transition: 0.33s ease;
  -o-transition: 0.33s ease;
  transition: 0.33s ease;
}


.translate3d( @coords ){
  -webkit-transform: translate3d(@coords);
  -moz-transform: translate3d(@coords);
  -ms-transform: translate3d(@coords);
  -o-transform: translate3d(@coords);
  transform: translate3d(@coords);
}


.footerlink(){
  font-family: @stdFont;
  color: @stdTextColor;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;

  &:hover, &:focus, &:active{
    color: @black;
  }
}