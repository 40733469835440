/**

  ALL VIDEO CLASSES


 */

// videos container | type DIV
.video{}
// videos youtube element | type DIV
.video--yt{}

// video wrapper for responsive behavior
.video--wrapper{
  position: relative;
  padding-bottom: 56.25%;
  height: 0;

  iframe{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}