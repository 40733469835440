.identity{
  z-index: 555;
  height: 230px;
  margin: 0;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: @lightblue;
  .interactionTransition();

  .header--identity{
    height: 160px;
    .interactionTransition();

    @media @tabP{
      height: 90px;
    }

    @media @smL{
      height: 66px;
    }
  }

  .container{
    position: relative;
    height: auto;
  }

  .logo--link{
    width: auto;
    height: 100%;
    box-sizing: border-box;
    padding: 5px 0;
    .interactionTransition();

    .logo--image{
      width: auto;
      height: 100%;
      display: block;
      .interactionTransition();
    }
  }


  @media @tabL{
    //height: 110px;

    .logo--link{
      width: auto;
      height: 100%;

      .logo--image{
        width: auto;
        height: 100%;
        display: block;
      }
    }
  }

  @media @tabP{
    height: 160px;

    .logo--link{
      height: 90px;
      margin-top: 10px;
      padding-right: 0;
    }
  }

  @media @smL{
    height: 66px;
    display: block;
  }



  &.identity--slim{
    height: 130px;

    @media @smL{
      height: 66px;
      display: block;
    }

    .header--identity{
      height: 90px;
    }

    .logo--link{
      width: auto;
      height: 90px;
      .interactionTransition();

      .logo--image{
        width: auto;
        height: 100%;
        display: block;
      }
    }
  }
}

.container > .mobile-hamburger{
  float: right;
  margin-top: 0.75rem;
}