body{
  font-family: @stdFont;
  color: @stdTextColor;
  line-height: @stdLineHeight;
  background: @bodybg;
}

.row{
  margin: 7rem 0;

  .row{margin: 0;}
}

.admin .contentElement{
  position: relative;
}

.hamburger{
  display: none;
}


h1{
  font-size: @h1size;
  text-transform: uppercase;
  color: @stdTextColor;
  font-weight: 500;
  margin-bottom: 2.5rem;
}
h2{
  font-weight: 500;
  color: @mediumgrey;
  margin-bottom: 2rem;
  text-transform: uppercase;
  font-size: @h2size;
}
h3{
  margin-top: 3rem;
}
.page--referenzen h3{
  margin-top: 1rem;
}
h4{}
h5{}
h6{}

h3 + ul{
  margin-top: 2rem;
}

main p{
  margin-bottom: 1.5rem;
}
main p{
  margin-top: 1.5rem;
}

.dark{
  background: @black;
  color: @white;

}
.white{
  background: @white;
}
.lightgrey{
  background: @lightGrey;
}

.centered{
  text-align: center;
}

strong{
  font-weight: 600;
}


// makes elements of row.flex are aligned in the middle of the row
.flex{

  width: 100%;
  & > .container{

    display: flex;
    //align-items: center;

    @media @tabP{
      // since tabletPortrait flex items should appear as column
      flex-direction: column;
    }
  }
}



@media @tabP{

  .mobile--reverse .container{
    display: flex;
    flex-direction: column-reverse;
  }
}