.website--footer{

  font-size: 0.875rem;
  background: @darkblue;
  color: @mediumblue;
  padding: 6rem 0 6rem 0;
  margin: 0;

  p{
    margin-bottom: 0.875rem;
  }

  .footer--label{
    display: inline-block;
    width: 48px;
  }

  a{
    color: @mediumblue;
    margin-left: 0.75rem;

    &:hover, &:active{
      color: @white;
    }
  }
}



.footer--nav{

  @media @smL{
    margin-top: 2rem;
  }

  li{
    display: inline-block;

    @media @smL{
      width: 33%;

      a{
        color: @lightblue;
        margin-left: 0;
        display: block;
      }
    }

  }


}

.footer--social{
  margin-top: 2rem;
  display: flex;
  width: 100%;
  box-sizing: border-box;
  padding-left: 0.875rem;

  @media @smL{
    padding-left: 0;
  }

  .social--label{
    line-height: 30px;
    color: @white;
  }

  a{
    width: 30px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 18px;
    margin-right: 1rem;
    color: @black;
    background: @lightblue;

    &:hover{
      background: @blue;
      color: @white;
    }
  }
}
