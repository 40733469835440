.dark{
  background: @darkgrey;
  color:@white;

  h2{
    color: @white;
    margin: 0 0 3rem;
  }


  // individuals
  &.leistungen--privat{

    //background: @darkgrey url("../content/index/img/privatkunden.jpg") no-repeat;
    background: @darkgrey;
    background-size: contain;
    background-position: 50vw 0;

    @media @tabP{}

    @media @smL{
      background-size: contain;
      background-position: 0 0;
    }


    li{
      margin-bottom: 1rem;
      padding-left: 2rem;
      position: relative;
      padding-top: 0.3rem;
      font-weight: 500;

      &:before{
        position: absolute;
        font-family: @fontawesome;
        top: 0.4rem;
        left: 0;
        content: "\f046";
        color: @white;
        border: 0;
      }
    }

    .contentElement{


    }

    .content50L{

      .contentElement{
        position: relative;
        padding: 5rem 0 5.1rem 0;
        @media @tabP{}

        @media @smL{
          padding-top: 5rem;
        }

        .tellabel{
          display: block;
          position: absolute;
          height: 52px;
          bottom: 0;
          left: ~"calc(100% + 2rem)";
          width: 280px;
          background: @blue;
          color: @white;
          content: "Telefonische Beratung";
          text-align: center;
          font-size: 1.25rem;
          padding-top: 1rem;

          @media @smL{
            display: none;
          }
        }
      }

      @media @smL{
        margin-bottom: 2rem;
      }
    }
    .content50R{
      .contentElement{
        padding: 0;

        figure{
          margin: 0;
        }

        @media @tabP{}

        @media @smL{
          padding: 0 0 5rem 0;
        }
      }
    }

    .button{
      position: absolute;
      bottom: 0;
      right: -2rem;
      padding: 1rem 2rem 1rem 4rem;
      font-size: 1.5rem;
      font-weight: 500;
      color: @darkblue;
      background: @white;

      @media @smL{
        right: 0;
        left: 0
      }


      &::after{
        display: block;
        position: absolute;
        top: 0;
        left: 100%;
        bottom: 0;
        width: 100%;
        background: @blue;
        color: @white;
        content: "Telefonische Beratung";
        text-align: center;
        font-size: 1.25rem;
        padding-top: 1rem;

        @media @smL{
          display: none;
        }
      }

      &::before{
        position: absolute;
        font-family: @fontawesome;
        font-size: 1.75rem;
        top: 0.75rem;
        left: 2rem;
        content: "\f2a0";
        color: @blue;

        @media @smL{
          display: none;
        }
      }
    }
  }

}


//.page--leistungen .dark.leistungen--privat .content50R .contentElement{
body:not(.page--index) .dark.leistungen--privat .content50R .contentElement{
  padding: 5rem 0 5.1rem;

  @media @smL{
    padding: 0 0 5rem 0;
  }
}




.flex{
  .container{
    & > div{
      height: 100%;
    }
  }
}

.page--downloads #ce-1{
  margin-top: 2rem;
}


.page--impressum,
.page--datenschutz{
  header .row.dark{
    margin: 0 0 3rem 0;
    padding: 3rem 0;

    h1{
      color: @white;

      @media @smX{
        font-size: 1.5rem;
      }
    }
  }

  main #ce-2{
    margin-top: 3rem;
  }
}