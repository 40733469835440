// disable grids standard padding for divs inside .container
.container > .navigation{
  padding: 0;
}



// Standard Navigation Styles
.navigation{

  background: @blue;

  .menu--mainnav{
    display: table;
    width: 100%;

    li{
      float: left;
    }
    .active{

    }
    .item--hasChild{
      position: relative;

      & > a{
        float: left;
      }
    }

    a{
      display: block;
      height: 75px;
      box-sizing: border-box;
      font-family: @linkFont;
      padding: 15px 1rem 15px 1rem;
      font-size: 1.125rem;
      line-height: 45px;
      color: @white;

      .interactionTransition();

      &:hover{
        color: @darkblue;
        background: @white;
      }

      @media @tabP{
        font-size: 1rem;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
      }
    }

    .active{

      & > a{
        font-weight: 500;
        background: @darkblue;

        &:hover{
          color: @white;
        }
      }
    }

    .trigger--submenu{
      float: right;
      margin-top: 66px;
      color: @black;
      width: 30px;
      height: 44px;
      border: 0;
      background: none;
      text-align: left;
    }



    // Submenu Styles
    .item--submenu{
      position: absolute;
      z-index: 999;
      top: 100%;
      left: 0;
      width: auto;
      display: table;
      background: @white;
      white-space: nowrap;
      visibility: hidden;
      -webkit-transform: translate(0, 1.5rem);
      -moz-transform: translate(0, 1.5rem);
      transform: translate(0, 1.5rem);
      opacity: 0;
      .interactionTransition();

      li{
        display: table;
        width: 100%;
      }
      a{
        text-align: left;
        padding: 0 1rem;
        height: auto;
        line-height: 44px;

        &:hover, &:active, &:focus{
          background: @darkblue;
          color: #fff;
        }
      }
    }

    .submenu--in .item--submenu,
    .submenu--fixed .item--submenu{
      z-index: 999;
      visibility: visible;
      opacity: 1;
      -webkit-transform: translate(0, 0);
      -moz-transform: translate(0, 0);
      transform: translate(0, 0);
    }
  }
}



// Smaller Navigation Bar in case of having scrolled down
.scrolled .navigation .menu--mainnav{
  a{
    height: 40px;
    //padding-top: 12px;
    padding: 0 1rem;
    line-height: 40px;
  }

  .trigger--submenu {
    margin-top: 6px;
  }
}






// hide subnav trigger in case of beeing able to hover
.can--hover .navigation .menu--mainnav .trigger--submenu{display: none;}










@media @smL{

  // Mobile Menu - slided out

  .full-width header .header--navigation{

    position: fixed;
    top: 66px;
    left: 25%;
    width: 85%;
    box-shadow: 1px 1px 3px rgba(0,0,0,0.7);
    height: ~"calc(100vh - 66px)";
    display: block;
    z-index: 999;
    -webkit-transform: translate3d(101%,0,0);
    -moz-transform: translate3d(101%,0,0);
    -ms-transform: translate3d(101%,0,0);
    -o-transform: translate3d(101%,0,0);
    transform: translate3d(101%,0,0);
    background: @blue;
    .interactionTransition();

    .menu--mainnav{
      li{
        width: 100%;
        display: block;
        border-bottom: 1px solid @mediumblue;

        a{
          display: block;
          padding: 0 1rem;
          line-height: 44px;
          height: 44px;
          width: 100%;

          &:hover, &:focus, &:active{
            color: @white;
          }
        }


        &.item--hasChild{

          display: table;
          width: 100%;

          a{
            width: 80%;
            float: left;
          }

          .trigger--submenu{
            width: 20%;
            height: 44px;
            float: right;
            margin-top: 0;
            display: inline-block;
            text-align: center;
          }
        }
      }
    }
  }

  // Mobile Menu - slided in
  .off-canvas--in{

    header .header--navigation{
      -webkit-transform: translate3d(0,0,0);
      -moz-transform: translate3d(0,0,0);
      -ms-transform: translate3d(0,0,0);
      -o-transform: translate3d(0,0,0);
      transform: translate3d(0,0,0);
    }

  }

  // second level slides
  .navigation .menu--mainnav{
    position: relative;

    a{border: 0;}

    .item--hasChild{
      position: static;
    }


    .item--submenu{
      position: absolute;
      top: 0;
      left: 0;
      width: 80%;
      max-width: 80%;
      background: #bbb;
      height: ~"calc(100vh - 80px)";
      bottom: 0;
      visibility: visible;
      opacity: 1;
      -webkit-transform: translate3d(-102%,0,0);
      -moz-transform: translate3d(-102%,0,0);
      -ms-transform: translate3d(-102%,0,0);
      -o-transform: translate3d(-102%,0,0);
      transform: translate3d(-102%,0,0);

      li a{
        text-align: left;
      }
    }
  }

}





@media @smL{
  .hamburger{
    display: block;
    position: fixed;
    z-index: 577;
    top: 0;
    right: 1rem;
    padding: 0.75rem;
  }




  /* -----------------------------------------------------------

  HAMBURGER Styles

  ----------------------------------------------------------- */
  .hamburger{
    float: right;
    display: inline-block;
  }
  .off-canvas--trigger{
    width: 44px;
    height: 36px;
    font:inherit;
    display:inline-block;
    overflow:visible;
    margin: 10px 0 0 0;
    padding: 5px 0 5px 5px;
    cursor:pointer;
    transition-timing-function:linear;
    transition-duration:.15s;
    transition-property:opacity,-webkit-filter;
    transition-property:opacity,filter;
    transition-property:opacity,filter,-webkit-filter;
    text-transform:none;
    color:inherit;
    border:0;
    background-color:transparent;
  }
  .hamburger--box {
    position:relative;
    display:inline-block;
    width:32px;
    height:20px
  }
  .hamburger--inner {
    top:50%;
    display:block;
    margin-top:-2px
  }
  .hamburger--inner,.hamburger--inner:after,.hamburger--inner:before {
    position:absolute;
    width:36px;
    height:2px;
    transition-timing-function:ease;
    transition-duration:.15s;
    transition-property:-webkit-transform;
    transition-property:transform;
    transition-property:transform,-webkit-transform;
    border-radius:2px;
    background-color:@darkblue;
  }
  .hamburger--inner:before{width: 44px}
  .hamburger--inner:after,.hamburger--inner:before {
    display:block;
    content:''
  }
  .hamburger--inner:before {
    top:-10px;
    left: -8px
  }
  .hamburger--inner:after {
    bottom:-10px
  }
  .off-canvas--trigger .hamburger--inner {
    transition-timing-function:cubic-bezier(.55,.055,.675,.19);
    transition-duration:.1s
  }
  .off-canvas--trigger .hamburger--inner:before {
    transition:top .1s .14s ease,opacity .1s ease
  }
  .off-canvas--trigger .hamburger--inner:after {
    transition:bottom .1s .14s ease,-webkit-transform .1s cubic-bezier(.55,.055,.675,.19);
    transition:bottom .1s .14s ease,transform .1s cubic-bezier(.55,.055,.675,.19);
    transition:bottom .1s .14s ease,transform .1s cubic-bezier(.55,.055,.675,.19),-webkit-transform .1s cubic-bezier(.55,.055,.675,.19)
  }
  .off-canvas--trigger.hamburger--active .hamburger--inner {
    transition-delay:.14s;
    transition-timing-function:cubic-bezier(.215,.61,.355,1);
    -webkit-transform:rotate(45deg);
    transform:rotate(45deg)
  }
  .off-canvas--trigger.hamburger--active .hamburger--inner:before {
    top:0;
    transition:top .1s ease,opacity .1s .14s ease;
    opacity:0
  }
  .off-canvas--trigger.hamburger--active .hamburger--inner:after {
    bottom:0;
    transition:bottom .1s ease,-webkit-transform .1s .14s cubic-bezier(.215,.61,.355,1);
    transition:bottom .1s ease,transform .1s .14s cubic-bezier(.215,.61,.355,1);
    transition:bottom .1s ease,transform .1s .14s cubic-bezier(.215,.61,.355,1),-webkit-transform .1s .14s cubic-bezier(.215,.61,.355,1);
    -webkit-transform:rotate(-90deg);
    transform:rotate(-90deg)
  }


}