/**

  ALL BUTTON CLASSES


 */

// button's wrapper | type DIV
.button--wrapper{}

// wrapper's modifier in cas of given additional classes
.button--hasExtraClasses{}
// each additional class will appear as .button--hasExtraClass-%addClass%

// wrapper's modifier in case of external Link
.button--external{}

// the button itself | type A
.button{}
// each additional class will appear after button class on the link




// Card-a-like Buttons on home in col33 lightgrey
.buttons--card{

  padding-top: 4rem;
  padding-bottom: 4rem;

  .image{margin-bottom: 0;}
  .button--wrapper{
    background: @white;

    .button{
      padding: 2.5rem 1rem 2.5rem 1rem;
      font-size: 1.125rem;
      font-weight: 400;
      text-align: center;
      display: block;
    }
  }
}



// Button Full Blue
.button--blue{
  display: block;
  padding: 4rem 3rem 3rem;
  background: @blue;
  font-size: 1.15rem;
  color: @white;
  line-height: 1.5;


}

// Button Full white
.button--white{
  display: block;
  padding: 4rem 3rem 3rem;
  background: @white;
  font-size: 1.15rem;
  color: @darkblue;
  line-height: 1.5;
}

.button--lightblue{
  display: block;
  padding: 4rem 3rem 3rem;
  background: @lightblue;
  font-size: 1.15rem;
  color: @darkblue;
  line-height: 1.5;
}

.button--dark{
  display: block;
  padding: 4rem 3rem 3rem;
  background: @darkblue;
  font-size: 1.15rem;
  color: @lightblue;
  line-height: 1.5;
}

.button--blue,
.button--white,
.button--lightblue,
.button--dark{
  .interactionTransition();

  @media @smL{
    padding: 3rem 2rem 2rem;
  }
}

.header--index {
  .button--blue,
  .button--white{
    &:hover{
      background: @darkblue;
      color: @lightblue;
    }
  }
}






.buttons--big{
  .button--blue,
  .button--lightblue{
    margin-bottom: 1rem;
  }

  .button--blue,
  .button--dark{
    &::after{
      margin-left: 1rem;
      transform: translate(0,-4px);
      width: 60px;
      height: 5px;
      background: url('../globals/img/arrow-white.png') no-repeat center center;
      content: "";
      display: inline-block;
    }
  }

  .button--lightblue{
    &::after{
      margin-left: 1rem;
      transform: translate(0,-4px);
      width: 60px;
      height: 5px;
      background: url('../globals/img/arrow-blue.png') no-repeat center center;
      content: "";
      display: inline-block;
    }
  }


  .button--blue,
  .button--dark,
  .button--lightblue {
    &::after{
      .interactionTransition();
    }
    &:hover::after {
      transform: translate(1rem,-4px);
    }
  }
}



// Call to action Buttons
.cta--inner{
  display: table;
  width: 100%;
  margin: 2rem 0;
}
.cta--button{
  padding: 1rem 2rem;
  float: left;
  font-weight: 500;

  &.cta--button-blue{
    color: @white;
    background: @blue;
    float: right;
  }

  &.cta--button-white{
    color: @darkblue;
    background: @white;
  }

  @media @smL{
    display: block;
    float: none;
    width: 100%;
    margin-bottom: 1rem;
    box-sizing: border-box;
    text-align: center;
  }
}
