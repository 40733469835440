header {
  padding-top: 230px;
  .interactionTransition();

  @media @smL{
    padding-top: 66px;
  }
}



.header--index{
  box-sizing: border-box;
  margin: 0;
  padding-top: 400px;
  height: 650px;
  background: url("../content/index/img/header-index.jpg") no-repeat center center;
  background-size: cover;

  @media @tabP{

  }

  @media @smL{
    height: 80vh;
    min-height: 300px;
    padding-top: 40vh;
    background: url("../content/index/img/header-index-mobile.jpg") no-repeat bottom center;
    background-size: cover;



    .button--blue{
      margin-bottom: 1rem;
      padding: 1rem 2rem;
    }

    .button--white{
      margin-bottom: 1rem;
      padding: 1rem 2rem;
    }
  }

  @media @smL and (min-height: 650px){
    height: 70vh;
    padding-top: 30vh;
  }
}




.header--leistungen, .header--referenzen{
  margin: 0;
}




.header--slim{
  margin: 0 0 5rem 0;
  padding: 5rem 0;
}

.header--3dplanung{
  //background: url("../content/3d-planung/img/3dplanung-header.jpg") no-repeat center center;
  //background-size: cover;
  display: none;
}