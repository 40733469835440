main{
  min-height: 600px;
}


.content66L .contentElement{
  max-width: 80%;

  @media @tabP{
    max-width: 90%;
  }
  @media @smL{
    max-width: 100%;
  }
}



.content66R .contentElement{
  max-width: 80%;
  margin-left: 20%;

  @media @tabP{
    max-width: 90%;
    margin-left: 10%;
  }
  @media @smL{
    max-width: 100%;
    margin-left: 0;
  }
}



.page--referenzen{

  .content10033{

    h3 + p{
      margin-top: 0;
    }

    @media @smL{
      .content33L,
      .content33C{
        margin-bottom: 2rem;
      }
    }
  }
}


