.menu--inline-leistungen{
  display: flex;
  width: 100%;
  margin: 4rem 0;

  li{
    flex: 1;
    margin-left: 1rem;
    margin-right: 1rem;

    &:first-child{
      margin-left: 0;
    }
    &:last-child{
      margin-right: 0;
    }

    a{
      padding: 6rem 2rem 5rem 2rem;
      background: @blue;
      color: @white;
      display: block;
      box-sizing: border-box;
      text-align: center;
      font-weight: 500;
      position: relative;
      .interactionTransition();

      &::after{
        display: block;
        position: absolute;
        height: 2rem;
        width: 1px;
        background: @white;
        bottom: 2rem;
        left: 49%;
        content: "";
        opacity: 0;
        .interactionTransition();
      }
      &::before{
        display: block;
        position: absolute;
        height: 0;
        width: 0;
        border: 5px solid transparent;
        border-top: 5px solid @white;
        bottom: 1.5rem;
        left: 49%;
        margin-left: -4.5px;
        content: "";
        opacity: 0;
        .interactionTransition();
      }

      &:hover{
        padding: 4rem 2rem 7rem 2rem;

        &::before,
        &::after{
          opacity: 1;
        }
      }
    }
  }







  @media @tabP{}


  @media @smL{
    margin: 4rem 0 0 0;
    flex-direction: column;

    li, li:first-child{
      margin: 0 0 1rem 0;

      a, a:hover{
        padding: 3rem 2rem 2rem 2rem;

        &::after, &::before{
          display: none;
        }
      }
    }
    li:last-child{
      margin: 0;
    }
  }


}