/**

  ALL DOCUMENT CLASSES


 */

// doc link's wrapper | type DIV
.doc--wrapper{
  margin: 1rem 0;
}

// wrapper's modifier in case of additional Classes
.doc--hasExtraClasses{}
// each additional class will appear as .doc--hasExtraClass-%addClass%


// doc's link | type <a>
.doc--link{

  display: inline-block;
  padding: 0.33rem 0.75rem;
  background: @blue;
  color: @white;
  .interactionTransition();

  &:hover,
  &:focus,
  &:active{
    background: @darkblue;
  }

  // Text of the Link | type SPAN
  .doc--link-text{
    margin-right: 0.75rem;
  }

  // Type of the linked document | type SPAN
  .doc--link-type{
    font-weight: 400;
    font-size: 0.875rem;

    @media @smL{
      display: none;
    }
  }

  // Size of the linked document | type SPAN
  .doc--link-size{
    font-weight: 400;
    font-size: 0.875rem;
  }
}

// doc's link modifier in case of link in new tab
.doc--link-external{}





@media @smL{
  .page--team{
    .doc--link{
      display: block;
      width: 100%;
      box-sizing: border-box;
    }
  }
}