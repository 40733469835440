main .row, .container > .headingCol{
min-height: 44px;
}

main a{
  font-weight: 400;
  color: @black;
}





.scroller {
  width: 2em;
  height: 2em;
  background: rgba(0,0,0,0.2) url('../core/img/toparr.svg')no-repeat center center;
  background-size: 50% auto;
  color: #fff;
  position: fixed;
  z-index: 222;
  bottom: 2em;
  left: -2em;
  -moz-transition: 0.3s ease;
  -webkit-transition: 0.3s ease;
  transition: 0.3s ease;
  overflow: hidden;
  display: block;
  text-indent: 100px;
  white-space: nowrap;
}
.scroller.come-in {
  left: 1em;
}
@media @smL{
  .popover--in .scroller{bottom: 6rem;}
}